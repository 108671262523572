<template>
  <b-container>
    <div class="text-center mt-5 mb-4">
      <b-row class="mb-4">
        <b-col><img src="../../../assets/images/curva/social.png" class="social-img"></b-col>
      </b-row>
      <curva-title :title="$t('social.coming')"/>
      <p class="text-warning font-size-22">{{ $t('social.tuned') }}</p>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  mounted () {
    core.index()
  }
}
</script>
<style>
@media only screen and (max-width: 500px) {
  .social-img {
    width: 100%
  }
}
</style>
